

$dark-mode-background: #181818;
$dark-mode-light-background: #1f1f1f;

$dark-mode-border: #333333;

$dark-mode-hover: #282828;
$dark-mode-light-hover: #383838;

$dark-mode-text: #666666;
$dark-mode-light-text: #999999;

body {
    background-color: $dark-mode-background;
}

//dark border 2b2b2b
//light border 333333

//over dark hover 282828






//light 1f1f1f
//dark 181818

//dark hover 323232

//dark border 2b2b2b

//light light border 333333
//dark light border 2b2b2b
//dark dark border 

// light grey text 1f1f1f

//dark dark text 939393
//dark light text 565656

// dark text 868686